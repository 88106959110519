import useSWR from 'swr'

const fetcher = (...args) => fetch(...args).then(res => res.json())

export default function usePinned (tag, flushCache) {
  const filter = { tag }

  if (flushCache) {
    filter.flush = 1
  }

  const { data, error } = useSWR(`/api/pinnedPost?filter=${JSON.stringify(filter)}`, fetcher)

  return {
    pinnedPost: data,
    isLoading: !error && !data,
    isError: error
  }
}
