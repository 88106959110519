import Masonry from './Masonry'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import TwitterIcon from './Icons/Twitter'
// import * as gtag from '@/lib/gtag'
import usePinned from '@/hooks/usePinned'
import { useSWRInfinite } from 'swr'
import useUserInteractions from '@/hooks/useUserInteractions'
import { InteractionContext } from '@/contexts/InteractionContext'
import { useUser } from '@/hooks/useUser'

const Feed = ({
  applySequence = true,
  category,
  currentPost,
  currentPostIndex,
  fetchOnLoadOnly,
  filter,
  flush,
  grid,
  isAuthenticated,
  onPrepareData
}) => {
  const { setShowSignUpModal } = useContext(InteractionContext)
  const fetcher = (...args) => fetch(...args).then(res => res.json())

  const { pinnedPost, isLoading } = usePinned(category, flush)

  // load 10 articles if user is logged in, otherwise load 3
  const { user } = useUser()
  const PAGE_SIZE = user ? 10 : 3

  // A function to get the SWR key of each page,
  // its return value will be accepted by `fetcher`.
  // If `null` is returned, the request of that page won't start.
  const getKey = (pageIndex, previousPageData) => {
    // Reached the end?
    if (previousPageData && !previousPageData.length) {
      return null
    }

    // console.log('pageIndex :>> ', pageIndex)
    const defaultFilter = { 'tags.value': { $nin: ['news'] } }

    if (filter && !filter['tags.value']) {
      filter = {
        ...filter,
        ...defaultFilter
      }
    }

    return `/api/posts?page=${
      pageIndex + 1
    }&limit=${PAGE_SIZE}&filter=${JSON.stringify(filter || defaultFilter)}${
      flush ? '&flush=1' : ''
    }` // SWR key.
  }

  const { data, error, size, setSize } = useSWRInfinite(getKey, fetcher)
  const interactionResponse = useUserInteractions(user)
  const { interactions } = interactionResponse

  // const [currentActivePost, setCurrentActivePost] = useState(currentPost)
  // const [currentActivePostIndex, setCurrentPostActiveIndex] = useState(currentPostIndex)
  // const [index, setIndex] = useState(1)
  // const [showModal, setShowModal] = useState(false)

  if (!data) {
    return (
      <button className='opacity-75 pointer-events-none black button animate-pulse'>
        <div className='flex items-center space-x-2'>
          <span>Loading posts</span>{' '}
          <svg
            className='w-4 h-4 text-white animate-spin'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <line x1='12' y1='6' x2='12' y2='3' />
            <line x1='16.25' y1='7.75' x2='18.4' y2='5.6' />
            <line x1='18' y1='12' x2='21' y2='12' />
            <line x1='16.25' y1='16.25' x2='18.4' y2='18.4' />
            <line x1='12' y1='18' x2='12' y2='21' />
            <line x1='7.75' y1='16.25' x2='5.6' y2='18.4' />
            <line x1='6' y1='12' x2='3' y2='12' />
            <line x1='7.75' y1='7.75' x2='5.6' y2='5.6' />
          </svg>
        </div>
      </button>
    )
  }

  const posts = data ? [].concat(...data) : []
  const isEmpty = data && data[0] && data[0].length === 0
  const isLoadingInitialData = !data && !error
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < PAGE_SIZE)
  // const isRefreshing = isValidating && data && data.length === size

  // Load More button CSS.
  const cssClasses = [
    'button',
    'button--load-more',
    'font-normal',
    'w-full',
    'lg:w-auto'
  ]

  if (isLoading || isReachingEnd) {
    cssClasses.push('pointer-events-none')
    cssClasses.push('opacity-75')
  }

  if (isReachingEnd) {
    cssClasses.push('hidden')
  }

  posts.forEach(post => {
    post.userInteractions = interactions
  })

  return (
    <>
      <div className={`relative ${!user ? 'pointer-events-none' : ''}`}>
        <Masonry
          data={{ posts }}
          onFetch={() => {}}
          onPrepareData={onPrepareData}
          onVisibilityChange={() => {}}
        />

        {/* overlay/cta shown to non-members */}
        {!user && (
          <div className='absolute top-0 left-0 z-20 flex items-start justify-center w-full h-full p-5 md:items-end md:p-20'>
            <div className='relative z-10 w-full p-10 mt-32 text-white border-2 border-white rounded-lg shadow-lg pointer-events-auto md:mt-0 bg-opacity-85 backdrop-blur bg-darkGrey border-opacity-10'>
              <div className='container flex flex-col items-center justify-center h-full px-3 mx-auto md:flex-row md:justify-between lg:px-0'>
                <div className='flex flex-col text-center md:text-left'>
                  <div className='font-medium leading-none text-white uppercase subtitle'>
                    Content is for members only
                  </div>
                  <div className='mt-5 text-sm font-light text-gray-400 md:mt-2 md:text-base'>
                    Become a Scramblers member to access great content – and
                    exclusive events
                  </div>
                </div>
                <button
                  className='flex-shrink-0 mt-5 button button--accent md:mt-0'
                  onClick={() => {
                    setShowSignUpModal(true)
                  }}
                >
                  Join us, it's free!
                </button>
              </div>
            </div>
            <div className='absolute bottom-0 w-full h-full bg-darkGrey md:bg-transparent md:bg-gradient-to-t from-darkGrey via-darkGrey opacity-85 md:opacity-95' />
          </div>
        )}
      </div>

      {user && (
        <div className='flex flex-col items-center justify-between w-full pb-10 mt-10 lg:flex-row'>
          {/* {user && ( */}
          <button
            className={cssClasses.join(' ')}
            disabled={isLoadingMore || isReachingEnd}
            onClick={() => setSize(size + 1)}
          >
            {isLoadingMore ? (
              <div className='flex items-center space-x-2'>
                <span>Loading</span>{' '}
                <svg
                  className='w-4 h-4 text-white animate-spin'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <line x1='12' y1='6' x2='12' y2='3' />
                  <line x1='16.25' y1='7.75' x2='18.4' y2='5.6' />
                  <line x1='18' y1='12' x2='21' y2='12' />
                  <line x1='16.25' y1='16.25' x2='18.4' y2='18.4' />
                  <line x1='12' y1='18' x2='12' y2='21' />
                  <line x1='7.75' y1='16.25' x2='5.6' y2='18.4' />
                  <line x1='6' y1='12' x2='3' y2='12' />
                  <line x1='7.75' y1='7.75' x2='5.6' y2='5.6' />
                </svg>
              </div>
            ) : !isReachingEnd ? (
              <div className='flex items-center space-x-2'>
                <span>Load More</span>{' '}
                <svg
                  className='w-4 h-4'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M15 4v8h3.586a1 1 0 0 1 .707 1.707l-6.586 6.586a1 1 0 0 1 -1.414 0l-6.586 -6.586a1 1 0 0 1 .707 -1.707h3.586v-8a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1z' />
                </svg>
              </div>
            ) : (
              ''
            )}
          </button>
          {/* )} */}
          {isReachingEnd ? (
            <div className='flex flex-col justify-center space-y-0 font-light text-center text-white lg:justify-start lg:text-left'>
              <h3 className='text-xl font-normal text-white'>
                You've reached the end
              </h3>
              <p className='text-white'>
                It’s early days! We’re adding new content each day but for now
                that’s it.
                <br className='hidden md:block' />
                Looking for more? Try Scramblers on{' '}
                <a
                  href='https://instagram.com/wearescramblers'
                  target='_blank'
                  rel='noreferrer'
                  className='text-white underline'
                >
                  Instagram
                </a>
                ,{' '}
                <a
                  href='https://twitter.com/wearescramblers'
                  target='_blank'
                  rel='noreferrer'
                  className='text-white underline'
                >
                  Twitter
                </a>{' '}
                or{' '}
                <a
                  href='https://facebook.com/wearescramblers'
                  target='_blank'
                  rel='noreferrer'
                  className='text-white underline'
                >
                  Facebook
                </a>
                .
              </p>
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}

Feed.propTypes = {
  applySequence: PropTypes.bool,

  // The currently active tag.
  category: PropTypes.string,

  // A JSON-formatted filter for passing through to
  // the document loading process. Passed from pages such as
  // 'category' and 'tag' pages.
  filter: PropTypes.object,

  grid: PropTypes.bool,

  // A function capable of preparing data for display before it is passed to the underlying layout component.
  onPrepareData: PropTypes.func
}

export default Feed
